// main imports
import * as React from "react";
import { graphql } from "gatsby";

// plugin imports
import { Trans, useI18next, useTranslation } from "gatsby-plugin-react-i18next";

// style imports
import "../../index.scss";
import "../menus.scss";

// svg imports

// components imports
import MenuLayout from "../../../components/Layout/MenuLayout.js";
import Seo from "../../../components/Seo/Seo.js";
import MenuTitle from "../../../components/Menus/MenuTitle";
import Description from "../../../components/Menus/Description";

// page
export default function IndexPage() {
	const { language } = useI18next();
	const { t } = useTranslation("menuValentines");

	return (
		<MenuLayout>
			<Seo lang={language} title={t("valentine's brunch menu")} />
			<div className="bodyContainer menu center">
				<section>
					<div>
						.· ❤ ·.
						<h1>
							bossa <Trans ns="menuValentines">valentine's brunch</Trans>.
						</h1>
						<Description>
							<Trans ns="menuValentines">
								served for two. choice per table
							</Trans>
							.
						</Description>
						<br />
						<br />
						<p>
							<Trans ns="menuValentines">aperitif. water</Trans>.
							<Description>passion fruit mimosa. or prosecco.</Description>
						</p>
						<p>
							<Trans ns="menuValentines">optional caviar 20. p.p</Trans>.
							<Description>30g. crème fraîche. potato. sourdough.</Description>
						</p>
						<br />
						<br />
						<br />
						❤
						<MenuTitle bold={t("starter")} descr="choose. one" />
						<p>
							<Trans ns="menuValentines">tuna carpaccio</Trans>.
							<Description>
								<Trans ns="menuValentines">daikon. raspberry. soy sauce</Trans>.
							</Description>
						</p>
						<p>
							<Trans ns="menuValentines">burrata</Trans>.
							<Description>
								<Trans ns="menuValentines">
									beetroot. pistachio. purple basil
								</Trans>
								.
							</Description>
						</p>
						<br />
						<br />
						<br />
						❤
						<MenuTitle bold={t("main course")} descr="choose. one" />
						<p>
							<Trans ns="menuValentines">picanha</Trans>.
							<Description>
								<Trans ns="menuValentines">corn. pearl onion</Trans>.
							</Description>
						</p>
						<p>
							<Trans ns="menuValentines">fregola sarda</Trans>.
							<Description>
								<Trans ns="menuValentines">tomato. bellpepper. parmesan</Trans>.
							</Description>
						</p>
						<br />
						<br />
						<br />
						❤
						<MenuTitle bold={t("dessert")} descr="choose. one" />
						<p>
							<Trans ns="menuValentines">macaron</Trans>.
							<Description>
								<Trans ns="menuValentines">strawberry. chantilly. oxalis</Trans>
								.
							</Description>
						</p>
						<p>
							<Trans ns="menuValentines">chocolate lava cake</Trans>.
							<Description>
								<Trans ns="menuValentines">baileys</Trans>.
							</Description>
						</p>
						<br />
						<br />
						<br />❤
						<br />
						<br />
						<br />
						<p>
							<Trans ns="menuValentines">petit four</Trans>.
							<Description>
								<Trans ns="menuValentines">belgian chocolate. strawberry</Trans>
								.
							</Description>
						</p>
						<br />
						<br />
						<p>
							<Trans ns="menuValentines">49. p.p</Trans>.
						</p>
						<br />
						<br />
						·. ❤ .·
					</div>
				</section>
			</div>
		</MenuLayout>
	);
}

// graphql query
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
